import { type GetStaticPropsResult } from 'next'
import Error from 'next/error'

export const NotFoundPage = () => {
  return (
    <Error
      title={`"Error Page (404)" is not set in Sanity, or the page data is missing`}
      statusCode={404}
    />
  )
}

export async function getStaticProps(): Promise<GetStaticPropsResult<unknown>> {
  return {
    props: {},
    revalidate: 60,
  }
}

export default NotFoundPage
